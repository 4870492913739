import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    lang: 'es',
    loggedIn: false,
    user: false,
    token: false,
    roles: [],
    modules: [],
    divisaBase: false,
    formasPago: [],
    appData: {
      usuarios: [],
      jugadores: [],
      equipos: [],
      posiciones: [],
      tipoIdentificacion: [],
      sucursales: [],
      estados: [],
    },
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_LANG (state, payload) {
      state.lang = payload
    },
    SET_LOGGED_IN (state, payload) {
      state.loggedIn = payload
    },
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_ROLES (state, payload) {
      state.roles = payload
    },
    SET_MODULES (state, payload) {
      state.modules = payload
    },
    SET_DIVISA_BASE (state, payload) {
      state.divisaBase = payload
    },
    SET_FORMAS_PAGO (state, payload) {
      state.formasPago = payload
    },
    SET_APP_DATA (state, payload) {
      Object.keys(payload).forEach(key => {
        Vue.set(state.appData, key, payload[key])
      })
    },
  },
  actions: {

  },
  plugins: [vuexLocal.plugin],
})
