// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

window.PRODUCTS_PER_PAGE = process.env.VUE_APP_PRODUCTS_PER_PAGE
window.SITE_NAME = process.env.VUE_APP_SITE_NAME
window.SITE_LOGO = process.env.VUE_APP_SITE_LOGO

import Vue from 'vue'
import FlagIcon from 'vue-flag-icon'
import LoadScript from "vue-plugin-load-script"

window.EventBus = new Vue()

import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import './plugins/sweet-modal'
import './plugins/vue-resource'
import './plugins/vue-moment'
import './plugins/mixins'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.config.productionTip = false

window.route = (method, endpoint = '') => {
  return [process.env.VUE_APP_API_ENDPOINT, method].join('/')
}

window.strapiAsset = (path = "") => {
  if (path.charAt(0) == "/") {
    return [process.env.VUE_APP_BACKEND_URL, path].join("")
  } else {
    return [process.env.VUE_APP_BACKEND_URL, path].join("/")
  }
}

Vue.use(FlagIcon)
Vue.use(LoadScript)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
