 <template>
  <div id="video-recorder">
    <v-row>
      <v-col
        class="d-flex"
        cols="12"
        v-if="!photoTaken"
      >
        <video
          ref="videoPlayer"
          playsinline
          autoplay
          style="margin: 0 auto; min-width: 200px"
          @click="startRecording"
          class="pointer"
        />
      </v-col>
      <v-col
        class="d-flex"
        v-if="photoTaken"
        align="center"
      >
        <img
          :src="photoTaken"
          v-if="photoTaken"
          style="margin: 0 auto"
          @click="restartComponent"
          class="pointer"
        />
      </v-col>
    </v-row>

    <div>
      <span id="errorMsg" />
    </div>

    <v-dialog
      v-model="showDeviceSelector"
      :max-width="320"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="showDeviceSelector = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.general.select_device') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="white"
            :disabled="!selectedDevice"
            @click="setSelectedDevice"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col class="align-content-center">
              <v-select
                v-model="selectedDevice"
                :items="deviceList"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'VideoRecordWindow',
    props: {
      width: {
        type: Number,
        default: 848,
      },
      height: {
        type: Number,
        default: 480,
      },
    },
    data () {
      return {
        showDeviceSelector: false,
        deviceList: [],
        selectedDevice: false,
        showCountdown: false,
        showUsedTime: false,
        usedTime: 0,
        buttonState: {
          start: false,
          record: true,
          stop: true,
          review: true,
          confirm: true,
        },
        constraints: {
          audio: {
            echoCancellation: {
              exact: true,
            },
          },
          video: {
            width: 1280, height: 720,
          },
          photo: {
            width: 640, height: 480, interval: 300, quantiy: 10,
          },
        },
        mediaSource: null,
        sourceBuffer: null,
        mediaRecorder: null,
        stream: null,
        recordedBlobs: null,
        internalState: {
          isRecording: false,
        },
        mediaRecorderOptions: {
          vp9: {
            mimeType: 'video/webm;codecs=vp9',
          },
          vp8: {
            mimeType: 'video/webm;codecs=vp8',
          },
          basic: {
            mimeType: 'video/webm',
          },
        },
        mediaRecorderOptionsSupported: null,
        isRecordingSupported: false,
        photoTaken: null
      }
    },
    computed: {
      endTime () {
        return (60 * 60 * 1000)
      },
    },
    async mounted () {
      this.$loadScript('https://webrtc.github.io/adapter/adapter-latest.js').then(()=>{}).catch(()=>this.errorMessage('No se ha podido cargar el script para activar WebRTC'))
      console.log('WebRTC Loaded')

      this.toggleLoader()
      this.constraints.video.height = this.height
      this.constraints.video.width = this.width

      this.constraints.photo.height = this.height
      this.constraints.photo.width = this.width

      await this.getDevices()
      this.toggleLoader()

      EventBus.$on('stop-recording', () => {
        this.closeRecordWindow()
      })
    },
    methods: {
      async setSelectedDevice () {
        this.showDeviceSelector = false
        await this.initCamera()
      },
      async getDevices () {
        try {
          let deviceInfos = await navigator.mediaDevices.enumerateDevices()
          let videoDevices = deviceInfos.filter(device => device.kind === 'videoinput')

          if (videoDevices) {
            for (let device of videoDevices) {
              this.deviceList.push({
                text: device.label,
                value: device.deviceId
              })
            }
            if (videoDevices.length > 1) {
              this.showDeviceSelector = true
            } else {
              this.initCamera()
            }
          }
        } catch (error) {
          this.errorMessage(`navigator.MediaDevices.getUserMedia error: ${error.message} | ${error.name}`)
        }
      },
      async initCamera () {
        try {
          if (this.selectedDevice) {
            this.constraints.video.deviceId = {exact: this.selectedDevice}
          }
          this.stream = await navigator.mediaDevices.getUserMedia(this.constraints)
          this.$refs.videoPlayer.srcObject = this.stream

          this.mediaSource = new MediaSource()
          this.mediaSource.addEventListener('sourceopen', () => {
            this.sourceBuffer = this.mediaSource.addSourceBuffer('video/webm; codecs="vp8"')
          }, false)

          this.isRecordingSupported = true

          this.buttonState.start = true
          this.buttonState.record = false
        } catch (e) {
          this.errorMessage(`No se ha podido iniciar la cámara. Error: ${e.toString()}`)
        }
      },
      async startRecording () {
        console.log('photo taken')
        let canvas = document.createElement('canvas')
        canvas.width  = this.constraints.photo.width
        canvas.height = this.constraints.photo.height
        canvas.getContext('2d').drawImage(this.$refs.videoPlayer, 0, 0, this.constraints.photo.width, this.constraints.photo.height)

        this.photoTaken = canvas.toDataURL('image/png')
        canvas.toBlob(blob => this.$emit('on-photo-taken', blob))

        this.stopRecording()
      },
      stopRecording () {
        this.internalState.isRecording = false
        if (this.mediaRecorder && this.mediaRecorder.isRecording) this.mediaRecorder.stop()
        if (this.stream) {
          this.stream.getTracks().forEach(track => {
            if (track.readyState == 'live') {
              track.stop()
            }
          })
        }

        this.buttonState.stop = true
        this.buttonState.review = false
        this.buttonState.confirm = false
      },
      playRecording () {
        this.$refs.videoPlayer.srcObject = this.stream
        const superbuffer = new Blob(this.recordedBlobs, {
          type: 'video/webm',
        })
        this.$refs.videoPlayer.src = null
        this.$refs.videoPlayer.srcObject = null
        this.$refs.videoPlayer.src = URL.createObjectURL(superbuffer)
        this.$refs.videoPlayer.controls = true
        this.$refs.videoPlayer.play()

        this.buttonState.review = true
        this.buttonState.start = false
      },
      confirmRecordedVideo () {
        const blob = new Blob(this.recordedBlobs, { type: 'video/webm' })
        const video = new File([blob], [Date.now(), '.webm'].join(''))

        this.showUsedTime = false
        this.showCountdown = false

        this.$emit('on-confirm-video', video)

        this.buttonState = {
          start: false,
          record: true,
          stop: true,
          review: true,
          confirm: true,
        }
      },
      closeRecordWindow () {
        this.stopRecording()

        this.showUsedTime = false
        this.showCountdown = false

        this.buttonState = {
          start: false,
          record: true,
          stop: true,
          review: true,
          confirm: true,
        }
      },
      onPauseCountdown (vm) {
        this.usedTime = vm.usedTime
        this.showCountdown = false
        this.showUsedTime = true
      },
      onFinishCountdown (vm) {
        setTimeout(() => {
          this.stopRecording()
        }, 1000)
      },
      async restartComponent () {
        this.toggleLoader()
        this.photoTaken = false
        this.$emit('on-photo-taken', false)
        await this.initCamera()
        this.toggleLoader()
      }
    },
  }
</script>

<style scoped>

</style>
