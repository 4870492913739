<template>
  <v-col>
    <section>
      <v-row>
        <v-col class="d-flex align-center">
          <h1
            class="font-weight-light mb-2 headline"
            v-text="`${title}`"
          />
        </v-col>
        <!--
        <v-col class="d-flex align-center">
          <v-row justify="end">
            <slot />
            <v-btn
              v-if="link"
              color="default"
              @click="goToLink"
            >
              {{ $t('app.buttons.back') }}
            </v-btn>
          </v-row>
        </v-col>
        -->
      </v-row>
    </section>
  </v-col>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      title: {
        required: true,
      },
      link: {
        required: false,
      },
    },
    methods: {
      goToLink () {
        this.$router.push({
          name: this.link,
        })
      },
    },
  }
</script>
